@import "./../../../../../../variable";


.wrapper{
  color:$theme-color-dark-blue;
  padding: 20px 50px;
  width: $content-width;
  height:518px;
  background-color:$theme-color-ultra-white;
  h1,h3{
    letter-spacing: 2px;
    margin: 0;
  }
  h1{
    font-size: 2.4em;
    font-weight: 700;
  }
  h2{
    font-weight: 400;
  }
  h4{
    font-size: 1em;
    font-weight: 400;
  }
  p{
    font-weight: 300;
    font-size: 1em;
  }
}
.seperator{
  margin: 15px 0 ;
  width: 150px;
  border: solid 2px $theme-color-dark-blue;
}
.detailItem{
  display: flex;
  width:300px;
  align-items: center;
  .icon{
    display: flex;
    justify-content: center;
    width:50px;
  }
  .description{
    p{
    font-weight: 400;
  }
  }
}
.detail{
  display: flex;
  flex-direction: column;
}
@media only screen and (max-width: 860px){
 .wrapper{
     width:100%;
}
}

@media only screen and (max-width: 520px){
  .wrapper{
     height:100%;
     h1{
       font-size:30px;
     }
     h2{
       font-size:20px;
     }
     h4{
       font-size:15px;
     }
  }
}
