@import './../../index.scss';

.wrapper{
  min-width: 400px;
  position: fixed;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $theme-background;
}
.layout{
  min-width: 400px;

  position: relative;
  z-index: 10;
  box-shadow: 2px 6px 13px -4px rgba(46,61,102,0.85);
}
.particle{
  position: fixed;
  height:100%;
  width:100%;
}

@media only screen and (max-width: 1300px) {
  .particle{
  display:none;
}
}
@media only screen and (max-width: 860px){
  .layout{
   width:95%;
}
}
@media only screen and (max-width: 520px){
  .layout{
   width:100%;
   height:80vh;
   min-height: 600px;
  }
}
