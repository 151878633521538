.card {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100px;
  height: 100px;
  background-color: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  animation: float 3s ease-in-out infinite;

  img {
    width: 100px;
    height: auto;
  }

  &:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
    cursor: pointer;
  }
}

@keyframes float {
  0%,
  100% {
    transform: translateY(0);
  }

  50% {
    transform: translateY(-10px);
  }
}
@media only screen and (max-width: 860px) {
  .card {
    width: 60px;
    height: 60px;
    img {
      width: 60px;
    }
  }
}
