@import "./../../../../../../variable";
.container{
  display: flex;
  flex-direction: column;
  justify-content: center;
  height:$content-height;
  .topFiller{
    flex:2;
  }
  .bottomFiller{
    flex:1;
  }
}

@media only screen and (max-width: 520px){
  .container{
     flex-direction: row;
     align-items: center;
     height: 45px;
     .topFiller{
       height: 45px;
     }
     .bottomFiller{
       height: 45px;
     }
  }
}




