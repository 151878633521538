@import "./../../../../../../variable";


.wrapper{
  color:$theme-color-dark-blue;
  padding: 20px 50px;
  width: $content-width;
  height:518px;
  background-color:$theme-color-ultra-white;
  h1,h3{
    letter-spacing: 2px;
    margin: 0;
  }
  h1{
    font-size: 2.4em;
    font-weight: 700;
  }
  h2{
    font-weight: 400;
  }
  h4{
    font-size: 1em;
    font-weight: 400;
  }
  p{
    font-weight: 300;
    font-size: 1em;
  }
}
.seperator{
  margin: 15px 0 ;
  width: 150px;
  border: solid 2px $theme-color-dark-blue;
}

.card{
  cursor: pointer;
  position: relative;
  width:200px;
  height:200px;

  &:hover{
    .front{
    transform: perspective(600px) rotateY(-180deg);
  }
  .back{
  transform: perspective(600px) rotateY(0deg);
}
  }
}
.cardContainer{

  width: 100%;
  display: flex;
}
.front,.back{
  border: solid 2px $theme-color-dark-blue;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: absolute;
  backface-visibility: hidden;
  transition: transform .6s linear;
}
.front img{
  height: 100%;
}
.front{
  transform: perspective(600px) rotateY(0deg);
}
.back{
  background: #f1f1f1;
  transform: perspective(600px) rotateY(180deg);
}
.backContent{
  color: #2c3e50;
  text-align: center;
  width: 100%;
}
@media only screen and (max-width: 860px){
 .wrapper{
     width:100%;
}
}
@media only screen and (max-width: 520px){
  .wrapper{
     height:100%;
  }
}