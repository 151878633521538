@import './../../../variable';

.wrapper{
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  font-size: 1.5em;
  color: $theme-color-ultra-white;
  text-decoration: none;
  border-right: solid 1px $theme-color-dark-blue;
  padding:3px 5px;
  width:50px;
  transition-property: background-color,color;
  transition-duration: 0.3s;
  &:hover{
    color: $theme-color-blue;
    background-color: $theme-color-ultra-white;
  }
}
@media only screen and (max-width: 1300px) {
  .wrapper{
    border: none;
  }
}