@import "./../../../../../../variable";


.wrapper{
  color:$theme-color-dark-blue;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: $content-width;
  justify-content: center;
  padding: 0 50px;
  height:518px;
  background-color:$theme-color-ultra-white;
  text-align: center;
  h1,h2,h3,h4{
    letter-spacing: 2px;
    margin: 0;
  }
  h1{
    margin: 10px;
    font-size: 2.4em;
     font-weight: 700;
  }
  h2{
    margin-top: 80px;
    font-weight: 400;
  }
  h4{
    font-size: 1em;
    font-weight: 500;
  }
  p{
    font-weight: 300;
    font-size: 1em;
  }
}
.seperator{
  margin: 15px;
  width: 35px;
  border: solid 2px $theme-color-dark-blue;
}
.location{
  display: flex;
  width:200px;
  justify-content: center;
  align-items: center;
  .icon{
    margin: 5px 10px;
  }
  .description{
    p{
    font-weight: 400;
  }
  }
}
@media only screen and (max-width: 860px){
 .wrapper{
     width:100%;
}
}

@media only screen and (max-width: 520px){
  .wrapper{
     height:100%;
  }
}
