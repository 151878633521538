

//content dimension
$content-height:518px;
$content-width:700px;

//color
$theme-color-black:#112d4e;
$theme-color-white:#f9f7f7;
$theme-color-blue:#3D4A6C;
$theme-color-dark-blue:#2E3D66;

$theme-color-ultra-white:#ffffff;

//background color
$theme-background:#f9f7f7;


