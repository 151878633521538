@import '../../index.scss';

.wrapper {
  display: flex;
  justify-content: center;
  padding: 45px;
  background-color: $theme-color-blue;
}

.content,.profile {
  flex: 1;
}
@media only screen and (max-width: 1300px){
  .wrapper{
     padding-left: 0;
  }
  .profile{
    display: none;
  }
}

@media only screen and (max-width: 520px){
  .wrapper{
     flex-direction: column;
     padding: 0 0 45px;
       height:100%;
    
  }
}


