@import './../../../../../../../../variable';

.wrapper{
  padding:5px 10px;
  margin-right: 2px;
  margin-bottom: 6px;
  background-color: $theme-color-blue;
  color: $theme-color-ultra-white;
  transition-property: color,background-color;
  transition-duration: 0.2s;
  &:hover{
    color: $theme-color-blue;
   background-color: $theme-color-ultra-white;
  }
  p{
    margin:0;
    font-weight: 500;
  }

}