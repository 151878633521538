@import './../../../../index.scss';

.container{
  // background-color: white;
  // color: $theme-color-blue;
  width: $content-width;
  overflow: hidden;
  height:$content-height;
}


@media only screen and (max-width: 860px){
 .container{
   width:100%;
   
}
}
@media only screen and (max-width: 520px){
  .container{
     height:100%;
  }
}
